import {
  Directive,
  inject,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { AuthStoreService } from '@core/services/auth-service/auth-store.service';
import { isDeepEqual } from '@core/helpers/deepEqual-helper';
import { PermissionOptions } from '@shared/model/permission-options.model';
import { hasPermissions } from '@shared/utils/parse/has-permission-util';

@Directive({
  selector: '[appHasPermission]',
  standalone: true,
})
export class HasPermissionDirective implements OnDestroy {
  private authStoreService = inject(AuthStoreService);
  private router = inject(Router);
  private templateRef = inject(TemplateRef);
  private viewContainer = inject(ViewContainerRef);

  isFullMatch: boolean | undefined = true;
  @Input() enableRedirect = false;
  @Input() display = 'block';
  getFeaturesSubscription: Subscription = new Subscription();

  @Input() set appHasPermissionFullMatch(fullMatch: boolean | undefined) {
    this.isFullMatch = fullMatch;
  }

  @Input()
  set appHasPermission(options: PermissionOptions | null) {
    if (this.getFeaturesSubscription) {
      this.getFeaturesSubscription.unsubscribe();
    }
    if (!options) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }
    this.getFeaturesSubscription = this.authStoreService.permissions$
      .pipe(distinctUntilChanged((a, b) => isDeepEqual(a, b)))
      .subscribe((userFeatures) => {
        const hasUserPermissions = hasPermissions(userFeatures, options);
        if (hasUserPermissions) {
          this.viewContainer.clear();
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
          if (options.enableRedirect) {
            this.router.navigateByUrl(`/403`);
          }
        }
      });
  }

  ngOnDestroy() {
    this.getFeaturesSubscription.unsubscribe();
  }
}
